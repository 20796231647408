import { defineStore, acceptHMRUpdate } from 'pinia';
import handleFrontendError from '../utils/handleFrontendError';
import type { Image, Tag } from '~/types/app.types';

export const useMediaLibraryStore = defineStore('Media Library', () => {
	const {
		data,
		error: fetchImagesError,
		pending: imagesLoading,
		refresh: fetchImages,
	} = useFetch<Image[]>('/api/images', {
		cache: 'no-cache',
		immediate: false,
		retry: 0,
		onResponseError({ response }) {
			handleFrontendError(
				response,
				true,
				'An error occurred while loading the media library images. Please try refreshing the page.',
			);
		},
	});

	const images = computed(() => {
		if (data.value && !imagesLoading.value) {
			return data.value;
		}
		return [];
	});

	const { data: tags, refresh: refreshTags } = useFetch<Tag[]>('/api/tags/all', {
		onResponseError({ response }) {
			handleFrontendError(response);
		},
	});

	const bulkSelectedImageIds = ref<string[]>([]);
	const bulkSelectActive = ref(false);
	const bulkSelectTagIds = ref<Tag[]>([]);

	const searchTerm = ref('');
	const tagFilterSelection = ref('All Tags');
	const excludedSearchProporties = ['id', 'url', 'fileSize', 'fileType'];

	const filteredImages = computed(() => {
		let filteredImages = images.value;
		if (searchTerm.value !== '') {
			filteredImages = filteredImages.filter((obj) => {
				for (const key in obj) {
					if (
						!excludedSearchProporties.includes(key) &&
						obj[key] !== null &&
						obj[key] !== undefined &&
						obj[key].toString().toLowerCase().includes(searchTerm.value.toLowerCase())
					) {
						return true;
					}
				}
				return false;
			});
		}
		if (tagFilterSelection.value !== 'All Tags') {
			filteredImages = filteredImages.filter((image) => {
				return image.tags?.some((tag) => tag.name === tagFilterSelection.value);
			});
		}
		return filteredImages;
	});

	function resetBulkSelect() {
		bulkSelectedImageIds.value = [];
		bulkSelectActive.value = false;
		bulkSelectTagIds.value = [];
	}

	function resetFilters() {
		searchTerm.value = '';
		tagFilterSelection.value = 'All Tags';
	}

	return {
		bulkSelectActive,
		bulkSelectedImageIds,
		bulkSelectTagIds,
		filteredImages,
		fetchImages,
		fetchImagesError,
		imagesLoading,
		images,
		refreshTags,
		resetBulkSelect,
		resetFilters,
		searchTerm,
		tags,
		tagFilterSelection,
	};
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useMediaLibraryStore, import.meta.hot));
}
